import './contact.css';

export default function Kontact() {
  return (
    <div id='contact' className='contact-image-container'>
      <p className='contact-text-location'>Based in Prague</p>

      <p className='contact-text'>
        Dedicated to the eternal detail of roaring overdrives, relentless fuzz
        tones, <br />
        crackling speakers, blowing tubes & spacediving echoes. <br />
        Recording, mixing & mastering for all overground <br />
        & underground musicians on our <br />
        void exploring rock spacecraft.
      </p>

      <div className='contact-link-container'>
        <a href='mailto: deadzeussound@gmail.com' className='contact-link'>
          contact
        </a>
      </div>
    </div>
  );
}
