import './store.css';
import Product from '../../assets/product.png';
import Reverb from '../../assets/reverb.png';
import Instagram from '../../assets/insta.png';
import Facebook from '../../assets/facebook.png';
import Doomlighter from '../../assets/doomlighter.png';

export default function Store() {

  return (
    <section id="store" className='store-container'>
      <div className='store-headline-container'>
        <h2 className='store-headline'>
          store
        </h2>
      </div>
      <div className="store-product-container">
        <div className="store-text-container">
          <img src={Doomlighter} className="store-doomlighter" alt="Doomlighter name"/>
          <p className="store-text">Doomlighter is an NPN germanium midrange booster guitar pedal. It has two channels. Channel A lives in the realms of "cleaner" drive boost, with gain and master volume knob. While Channel B operates in fuzzy, more distorted areas, with one master volume/gain knob. Each channel has its own three positions low cut filter. From heavy low end, to mids, to higher mids. Channel A also includes three positions output low end boost - from ultra low end, to natural mids, to higher presence. Each channel works separately, or in series (from A to B). The pedal works with regular 9V adapter (center negative).</p>
        </div>
        <a href="#">
          <img src={Product} className="store-img" alt="product"/>
        </a>
      </div>
      <div className="store-check-container">
        <p className="store-check-container-text">coming soon</p>
        {/* <a href="#">
          <img src={Reverb} className="store-check-container-img" alt="reverb link"/>
        </a> */}
      </div>
      <div className="store-sites-container">
        <a href="https://www.instagram.com/deadzeus_sound" target="_blank" rel="noopener noreferrer">
          <img src={Instagram} className="store-sites-img" alt="Instagram picture"/>
        </a>
        <a href="https://www.facebook.com/deadzeussound" target="_blank" rel="noopener noreferrer">
          <img src={Facebook} className="store-sites-img" alt="Facebook picture"/>
        </a>
      </div>
    </section>
  );
}
