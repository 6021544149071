import "./App.css";

import NavBar from "./components/navigation/nav-bar";
import MainImage from "./components/main-image/main-image";
import Kontact from "./components/contact/contact";
import Work from "./components/work/work";
import Store from "./components/store/store";

const App = () => {

  return (
    <div className="main-container">
      <NavBar />
      <MainImage />
      <Kontact />
      <Work />
      <Store />
    </div>
  );
};

export default App;
