import './main-image.css';
import DeadZeus from '../../assets/dead-zeus-mini.jpg';

export default function MainImage() {

  return (
    <div className="main-image-container">
      <img src={DeadZeus} className="navigation-img" alt='Dead Zeus Logo' />
    </div>
  );
}
