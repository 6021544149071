import './work.css';
import Sloth from '../../assets/sloth.jpg';
import Jakubysko from '../../assets/jakubysko.png';
import Trasher from '../../assets/party-trasher.jpg';
import Druha from '../../assets/druha-2.jpg';
import Violet from '../../assets/violet.jpg';
import Rudin from '../../assets/billy-rudin.jpg';
import Splicer from '../../assets/splicer.jpg';



export default function Work() {

  return (
    <div id="work" className="work-container">
      {/* <img src={Contact} className="contact-img" alt="contact Logo" /> */}


      <div className='work-headline-container'>
        <h2 className='work-headline'>
          work
        </h2>
      </div>
      <div className="work-gallery">
        <div className="work-gallery-item">
          <p className="work-gallery-title">Billy Rudin</p>
          <a href="https://open.spotify.com/album/3LCQIEmdOzSYrIRnUID40N?si=gmtCLfELRJqmgIOWzjTAcA" target="_blank" rel="noopener noreferrer">
            <img src={Rudin} className="work-gallery-item-img" alt="Billy Rudin Logo"/>
          </a>
        </div>
        <div className="work-gallery-item">
          <p className="work-gallery-title">Sloth</p>
          <a href="https://sloth420.bandcamp.com/releases" target="_blank" rel="noopener noreferrer">
            <img src={Sloth} className="work-gallery-item-img" alt="Sloth Album Logo"/>
          </a>
        </div>
        <div className="work-gallery-item">
          <p className="work-gallery-title">Druhá</p>
          <a href="https://druhahc.bandcamp.com/album/s-t" target="_blank" rel="noopener noreferrer">
            <img src={Druha} className="work-gallery-item-img" alt="Druhá album Logo"/>
          </a>
        </div>
        <div className="work-gallery-item">
          <p className="work-gallery-title">Party Trasher</p>
          <a href="https://partytrasher.bandcamp.com/track/pitgeons-of-tabarin?fbclid=IwAR3dysQO-C1Ybr501nfM03qg_ihyCrtFHIhHCnZOOe6X_0yh6QIJFX7AS-k" target="_blank" rel="noopener noreferrer">
            <img src={Trasher} className="work-gallery-item-img" alt="Party trasher album Logo"/>
          </a>
        </div>
        <div className="work-gallery-item">
          <p className="work-gallery-title">Jakubysko</p>
          <a href="https://jakubysko.bandcamp.com/album/s-t-2" target="_blank" rel="noopener noreferrer">
            <img src={Jakubysko} className="work-gallery-item-img" alt="Jakubysko album Logo"/>
          </a>
        </div>
        <div className="work-gallery-item">
          <p className="work-gallery-title">Violet Dawn</p>
          <a href="https://violetdawnmusic.bandcamp.com/album/from-basement-to-dawn" target="_blank" rel="noopener noreferrer">
            <img src={Violet} className="work-gallery-item-img" alt="Violet Dawn album Logo"/>
          </a>
        </div>
        <div className="work-gallery-item">
          <p className="work-gallery-title">Tape Splicer</p>
          <a href="https://open.spotify.com/album/6xvKhPPM6lhggLPcDoGBLA?si=EyCjGZEWQXyZsIKAqUBDiQ" target="_blank" rel="noopener noreferrer">
            <img src={Splicer} className="work-gallery-item-img" alt="Tape Splicer Logo"/>
          </a>
        </div>
      </div>
    </div>
  );
}
