import { useState } from 'react';
import { Link } from 'react-scroll';
import './nav-bar.css';
import Logo from '../../assets/logo.png';

export default function Navbar() {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  const handleOnClick = () => {
    setIsNavExpanded(!isNavExpanded);
  }

  // close and open hamburger menu after click on ClipboardItem
  // https://dev.to/nicm42/closing-a-navigation-menu-in-react-8ad

  return (
    <nav className='navigation'>
      <a href='/'>
        <img src={Logo} className='navigation-logo' alt='Logo' />
      </a>

      <div 
        className='hamburger' 
      >
        <input
          onClick={handleOnClick}
          type='checkbox'
          id='toggle'
          className='hamburger-input'
        />
        <label for='toggle' className='hamburger-label'></label>
      </div>

      <div
        className={
          isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'
        }
      >
        <ul>
          <li>
            <Link
              activeClass='active'
              to='work'
              spy={true}
              smooth={true}
              offset={-70}
              duration={800}
              // onClick={handleOnClick}
            >
              <a href='/home' className='navigation-link' >
                work
              </a>
            </Link>
          </li>
          <li>
            <Link
              activeClass='active'
              to='store'
              spy={true}
              smooth={true}
              offset={-70}
              duration={800}
            >
              <a href='/about' className='navigation-link'>
                store
              </a>
            </Link>
          </li>
          <li>
            <Link
              activeClass='active'
              to='contact'
              spy={true}
              smooth={true}
              offset={-70}
              duration={800}
            >
              <a href='/contact' className='navigation-link'>
                contact
              </a>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
